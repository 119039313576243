import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeSlider from "../../src/components/homeSlider"

import { Container, Button } from "reactstrap"

import imgServicesIcon1 from "../images/services-icon1.png"
import imgServicesIcon2 from "../images/services-icon2.png"
import imgServicesIcon3 from "../images/services-icon3.png"
import imgServicesIcon4 from "../images/services-icon4.png"
import imgServicesIcon5 from "../images/services-icon5.png"
import imgServicesIcon6 from "../images/services-icon6.png"
import imgServicesIcon7 from "../images/services-icon7.png"
import imgServicesIcon8 from "../images/services-icon8.png"

import imgManufactoryIcon1 from "../images/manufactory-icon1.png"
import imgManufactoryIcon2 from "../images/manufactory-icon2.png"
import imgManufactoryIcon3 from "../images/manufactory-icon3.png"
import imgManufactoryIcon4 from "../images/manufactory-icon4.png"
import imgManufactoryIcon5 from "../images/manufactory-icon5.png"

// import imgProject1 from "../images/project1.png"
// import imgProject2 from "../images/project2.png"
// import imgProject3 from "../images/project3.png"

import imgTeam1 from "../images/team1.png"
import imgTeam2 from "../images/team2.png"
import imgTeam3 from "../images/team3.png"
import imgTeam4 from "../images/team4.png"

import imgFeedFacebook from "../images/feed-facebook.png"
import imgFeedInstagram from "../images/feed-instagram.png"

const IndexPage = () => {

  const data = useStaticQuery(graphql`
    query {
      allInstagramContent(limit: 4, sort: {fields: timestamp, order: DESC}) {
        nodes {
          id
          caption
          permalink
          media_type
          localImage {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 90) {
                aspectRatio
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      clients: allFile(
        filter: { absolutePath: { regex: "/images/clients/" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 208, quality: 90) {
                aspectRatio
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      homeOfferBg: file(relativePath: { eq: "home-offer-bg.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      homeManufactoryBg: file(relativePath: { eq: "home-manufactory-bg.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const clientImages = data.clients.edges

  const clientLogos = clientImages.map((item, key) => {
    return (
      <Img fluid={item.node.childImageSharp.fluid} className="client-image" key={key} />
    )
  })

  return (
    <Layout>
      <SEO />
      <HomeSlider />
      <section className="orange-bg">
        <Container className="home-services">
          <div className="service-card">
            <img src={imgServicesIcon1} alt="Meble na wymiar" />
            <div className="header">Meble na wymiar</div>
            <div className="body">Produkujemy meble dopasowane do Twojego wnętrza.</div>
          </div>
          <div className="service-card">
            <img src={imgServicesIcon2} alt="Konstrukcje metalowe" />
            <div className="header">Konstrukcje metalowe</div>
            <div className="body">Konstrukcje małe i wielkoformatowe z aluminium i stali</div>
          </div>
          <div className="service-card">
            <img src={imgServicesIcon3} alt="Stoiska handlowe" />
            <div className="header">Stoiska handlowe</div>
            <div className="body">Projekt i kompleksowa realizacja stoisk handlowych</div>
          </div>
          <div className="service-card">
            <img src={imgServicesIcon4} alt="Konstrukcje metalowe" />
            <div className="header">Materiały reklamowe</div>
            <div className="body">Banery, litery przestrzenne, wyklejki.</div>
          </div>

          <div className="service-card">
            <img src={imgServicesIcon5} alt="Szycie i tapicerowanie" />
            <div className="header">Szycie i tapicerowanie</div>
            <div className="body">Firanki, zasłony, kanapy itp.</div>
          </div>
          <div className="service-card">
            <img src={imgServicesIcon6} alt="Ledony i neony" />
            <div className="header">Ledony i neony</div>
            <div className="body">Produkujemy i projektujemy ledony oraz neony</div>
          </div>
          <div className="service-card">
            <img src={imgServicesIcon7} alt="Wykańczanie pod klucz" />
            <div className="header">Wykańczanie pod klucz</div>
            <div className="body">Od projektu po realizację. Współpracujemy również z architektami wnętrz.</div>
          </div>
          <div className="service-card">
            <img src={imgServicesIcon8} alt="Magazynowanie" />
            <div className="header">Magazynowanie</div>
            <div className="body">Posiadamy duże przestrzenie magazynowe</div>
          </div>
        </Container>
      </section>
      <BackgroundImage fluid={data.homeOfferBg.childImageSharp.fluid}>
        <section className="home-offer">
          <Container>
            <h3>Kompleksowo zrealizujemy <br />Twoją wizję.</h3>
            <p>Przemyślany projekt, odpowiedni dobór materiałów oraz doświadczona ekipa produkcyjna — to wszystko oferujemy w standardzie. Jesteśmy po to, aby wspólnie z naszymi Klientami tworzyć różnorodne rozwiązania dopasowane do indywidualnych potrzeb.</p>
            <p>Oferujemy zarówno projekty wnętrz prywatnych — domów i mieszkań, jak i przestrzeni użyteczności publicznej, takich jak sklepy, restauracje, kawiarnie, hotele. Naszą przewagą jest pełna opieka nad projektem: od koncepcji, przez projekt wykonawczy, pomoc przy wyborze wyposażenia aż po produkcję mebli.</p>
            <p>Początkowo pracowaliśmy w oparciu o dostawców różnych rozwiązań, jednak z czasem rozwinęliśmy nasz park maszynowy do takiego rozmiaru, że obecnie wszystko wykonujemy u siebie. Dzięki temu mamy całkowitą kontrolę nad procesem produkcji i możemy pochwalić się nie tylko najwyższą jakością, ale także konkurencyjnymi cenami.</p>
          </Container>
        </section>
      </BackgroundImage>
      {/* <section className="white-bg">
        <Container className="home-projects">
          <h2><Link to={"realizacje"}>Nasze realizacje</Link></h2>
          <p>Zobacz relacje z naszych działań</p>
          <div className="projects">
            <div className="project">
              <img src={imgProject1} alt="Toaletki Beauty" />
              <div className="header">Toaletki Beauty</div>
              <div className="body">
                W Elektrowni Powiśle znajduje się salon Let’s Beauty, dla kótrego wyprodukowaliśmy toaletki, regały i stand kosmetyczny.
                Meble łączą w sobie konstukcję metalową i drewnianą. Lustra świecą jednostajnym światłem led, które podświetla frezowaną linię.
                Toaletki zostały wyprodukowane we współpracy z Belotto Design.</div>
              <div className="more"><Link to={"realizacje/lets-beauty"}><Button className="button-more">Więcej</Button></Link></div>
            </div>
            <div className="project">
              <img src={imgProject2} alt="Sony Music" />
              <div className="header">Sony Music</div>
              <div className="body">We współpracy z architektami wnętrz Rave Architects stworzylismy biuro dla pracowników Sony Music. Wykonaliśmy meble do kuchni głównej oraz aneksu kuchennego, a także zabudowę “Miting Intern”. Za ladą wykonaliśmy zaokrągloną zabudowę z perforowanej, złotej płyty, którą także wyfrezowaliśmy oraz lakierowalismy w naszej fabryce.</div>
              <div className="more"><Link to={"realizacje/sony-music-polska"}><Button className="button-more">Więcej</Button></Link></div>
            </div>
            <div className="project">
              <img src={imgProject3} alt="Witraże Beauty Hall" />
              <div className="header">Witraże Beauty Hall</div>
              <div className="body">Dla Elektrowni Powiśle opracowanliśmy metodę tworzenia nowoczesnych witraży. Do produkcji użyliśmy naszego wielkoformatowego lasera, którym cięliśmy blachę stalową na wcześniej wyrysowane przez projektantów elementy. Te duże puzle przetransportowaliśmy do Warszawy, aby na miejscu poskładać je w kolorową transparętną ścianę.</div>
              <div className="more"><Link to={"realizacje/toalety-beauty"}><Button className="button-more">Więcej</Button></Link></div>
            </div>
          </div>
        </Container>
      </section> */}
      <section className="white-bg">
        <Container className="home-offer-new">
          <h2>Oferta</h2>
          <div className="projects">
            <div className="project">
              <div className="header">Projekt wnętrz</div>
              <div className="body">Zaprojektujemy Twoje wymarzone mieszkanie lub lokal, który przykuje uwagę klientów<br /><br /></div>
              <div className="more"><Link to={"kontakt"}><Button className="button-more">Napisz do nas</Button></Link></div>
            </div>
            <div className="project">
              <div className="header">Reklama</div>
              <div className="body">Wykonujemy oraz projektujemy reklamy przestrzenne, wyspy handlowe, stoiska handlowe, podświetlane kasetony, standy oraz wiele innych rodzajów nośników reklamowych.</div>
              <div className="more"><Link to={"kontakt"}><Button className="button-more">Napisz do nas</Button></Link></div>
            </div>
            <div className="project">
              <div className="header">Produkcja</div>
              <div className="body">Nasi konstruktorzy wykonają dla Ciebie mebel na zamówienie lub akcesoria do Twojego wnętrza. Współpracujemy również z architektami wnętrz.</div>
              <div className="more"><Link to={"kontakt"}><Button className="button-more">Napisz do nas</Button></Link></div>
            </div>
          </div>
        </Container>
      </section>
      <BackgroundImage fluid={data.homeManufactoryBg.childImageSharp.fluid}>
        <section className="home-manufactory">
          <Container>
            <h2>Fabryka</h2>
            <p>Tu powstają wszystkie nasze realizacje - serie, które produkujemy od lat, zestawy mebli na wymiar oraz jednorazowe produkty.</p>
          </Container>
        </section>
      </BackgroundImage>
      <section className="black-bg">
        <Container className="home-services home-manufactory-services">
          <div className="service-card">
            <img src={imgManufactoryIcon1} alt="Produkcja mebli" />
            <div className="header">Produkcja<br />mebli</div>
            <div className="body">Projektowanie, produkcja, montaż mebli na wymiar i wolnostojących.</div>
          </div>
          <div className="service-card">
            <img src={imgManufactoryIcon2} alt="Obróbka płyt meblowych" />
            <div className="header">Obróbka płyt<br />meblowych</div>
            <div className="body">Formatowanie CNC, wiercenie i frezowanie CNC, cięcię kątowe, laminowanie.</div>
          </div>
          <div className="service-card">
            <img src={imgManufactoryIcon3} alt="Obróbka metalu" />
            <div className="header">Obróbka<br />metalu</div>
            <div className="body">Cięcie laserowe, frezowanie, gięcie, walcowanie blach i profili, spawanie stali i aluminium.</div>
          </div>
          <div className="service-card">
            <img src={imgManufactoryIcon4} alt="Lakierowanie" />
            <div className="header">Lakierowanie<br />&nbsp;</div>
            <div className="body">Lakierowanie natryskowe powierzchni z MDF i drewna. Lakierowanie proszkowe.</div>
          </div>
          <div className="service-card">
            <img src={imgManufactoryIcon5} alt="Produkcja reklam świetlnych" />
            <div className="header">Produkcja reklam świetlnych</div>
            <div className="body">Szyldy, logotypy podświetlane, oznakowanie budynków, meble ekspozycyjne, indywidualne projekty.</div>
          </div>
        </Container>
      </section>
      <section className="white-bg">
        <Container className="home-feed">
          <h2>Zobacz co u nas słychać</h2>
          <p>Odwiedź naszego facebooka i instagrama.</p>
          <div className="social-icons">
            <a href="https://www.facebook.com/nobodesign.eu" target="_blank" rel="noreferrer"><img src={imgFeedFacebook} alt="Facebook Nobo Design" title="Facebook Nobo Design" /></a>
            <a href="https://facebook.com/nobodesign.eu" target="_blank" rel="noreferrer"><img src={imgFeedInstagram} alt="Instagram Nobo Design" title="Instagram Nobo Design" /></a>
          </div>
          <div className="feed">
            {data.allInstagramContent.nodes.map(el => {
              return (
                <div className="feed-item">
                  <a href={el.permalink} target="_blank" rel="noreferrer">
                    <Img fluid={el.localImage.childImageSharp.fluid} />
                    <div className="caption"><p>{el.caption && el.caption.split('\n').map((item, key) => {
                      return <>{item}<br /></>
                    })}</p></div>
                  </a>
                </div>
              )
            })}
          </div>
          <div className="more">
            <Link to={"galeria"}><Button className="button-more">Pokaż więcej</Button></Link>
          </div>
        </Container>
      </section>
      <section className="white-bg">
        <Container className="home-team">
          <h2>Nasz Team</h2>
          <p>Nasz zespół składa się z projektantów, managerów, stolarzy, spawaczy, monterów, <br />operatorów rozmaitych maszyn takich jak laser czy cnc, a także lakierniów.<br />Każdy z dużą dbałością pracuje nad własnym zadaniem, aby stworzyć niepowtarzalny produkt.</p>
          <div className="teams">
            <div className="team">
              <img src={imgTeam1} alt="Ślusarze" />
              <div className="header">Ślusarze</div>
            </div>
            <div className="team">
              <img src={imgTeam2} alt="Stolarze" />
              <div className="header">Stolarze</div>
            </div>
            <div className="team">
              <img src={imgTeam3} alt="Projektanci" />
              <div className="header">Projektanci</div>
            </div>
            <div className="team">
              <img src={imgTeam4} alt="Monterzy" />
              <div className="header">Monterzy</div>
            </div>
          </div>
        </Container>
      </section>
      <section className="white-bg">
        <Container className="home-clients">
          <h2>Zaufali nam</h2>
          <div className="clients">
            {clientLogos}
          </div>
        </Container>
      </section>
    </Layout>

  )
}

export default IndexPage
