import React, { useState } from "react"
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const items = [
  {
    header: "Co robimy?",
    caption:
      "Zajmujemy się produkcją mebli. Możemy wyprodukować białe jak śnieg szafki kuchenne z uchwytami ze szczotkowanej stali. Możemy też wykonać eklektyczny kontuar z postarzanego drewna i zardzewiałych rur. Możemy powiesić szafki na suficie albo zakotwić je do podłogi. Czasami zdarza się, że przykręcamy je do ścian.",
  },
  {
    header: "Kompleksowe rozwiązania",
    caption:
      "Naszymi projektami zajmujemy się od A do Z, gdzie A to Absolutnie fantastyczne inspiracje, B to Bardzo ładne wiuzualizacje, C to Całkiem profesjonalne rysunki techniczne, D to Dalsze procesy czyli produkcja, a Z to Zdjęcia gotowego produktu."
  },
  {
    header: "Własna produkcja",
    caption:
      "Dysponujemy profesjonalnym parkiem maszynowym i pracujemy w wielu materiałach, ale co najważniejsze - mamy doświadczoną ekipę, która zna tajniki produkcji. Daje nam to całkowitą kontrolę nad procesem produkcyjnym i pozwala stosować optymalne rozwiązania do każdego projektu. Dzięki temu możemy być kreatywni i elastyczni."
  }
]

const HomeSlider = props => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: { absolutePath: { regex: "/images/slider/" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 1900, quality: 90) {
                aspectRatio
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const images = data.allFile.edges

  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = newIndex => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = images.map((item, key) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <Img fluid={item.node.childImageSharp.fluid} className="client-image" />
        <CarouselCaption
          captionText={items[key].caption}
          captionHeader={items[key].header}
        />
      </CarouselItem>
    )
  })

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      className="home-slider"
      interval="7000"
    >
      <CarouselIndicators
        items={images}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  )
}

export default HomeSlider
